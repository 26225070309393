/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import * as CONST from "../CONSTANTS";
// import ReactGA from "react-ga"

const Tcpa = () => {
  useEffect(() => {
    // ReactGA.initialize("UA-115903172-9")
    // ReactGA.pageview(window.location.pathname + window.location.search)
  });

  return (
    <Layout layoutType={CONST.LAYOUT_TCPA_PAGE}>
      <Seo title="TCPA Partners" />
      <div className="tcpa-section">
        <div className="container">
          <div className="section-header">
            <h2>TCPA partners</h2>
            <p>Last Update 8 November, 2023</p>
          </div>

          <ul>
            <li>100insure</li>
            <li>Accuquote</li>
            <li>Acquire Crowd</li>
            <li>Advise</li>
            <li>Aetna</li>
            <li>Affordable health associates llc</li>
            <li>AIG</li>
            <li>Alchemy Media Group</li>
            <li>All American Pool Safety Fence Co.</li>
            <li>All Web Leads</li>
            <li>America's Health Center</li>
            <li>America's Health Providers</li>
            <li>American Benefits</li>
            <li>American Benefits Zone</li>
            <li>American Health Marketplace</li>
            <li>American Health Reform Solutions</li>
            <li>American Select Health</li>
            <li>American Solar Network</li>
            <li>American Way Health</li>
            <li>Americo</li>
            <li>Amerilife</li>
            <li>
              ARISE Durable Medical Equipment, Prosthetics, Orthotics and
              Supplies LLC
            </li>
            <li>Ascend Health</li>
            <li>Association Health Care Management</li>
            <li>Bennett Insurance Agency</li>
            <li>Bennett FMO</li>
            <li>Better Living Health Services</li>
            <li>BIO-Genetics Laboratory</li>
            <li>Blue Summit</li>
            <li>Boost Health Insurance</li>
            <li>Boost Marketing Inc</li>
            <li>Call Centrix LLC</li>
            <li>Client Consent</li>
            <li>Choice Health</li>
            <li>Comfort Care</li>
            <li>Coverance Insurance Solutions</li>
            <li>Cozmic</li>
            <li>CRMarketing</li>
            <li>Datalot</li>
            <li>Debt relief of america</li>
            <li>Debt Service Network</li>
            <li>Digital BGA</li>
            <li>Digital Thrive</li>
            <li>Drivebpo</li>
            <li>e-TeleQuote Insurance Inc</li>
            <li>Easy Health Insurance Services</li>
            <li>Effortless Financial Group</li>
            <li>eHealth</li>
            <li>Elite Insurance Partners, llc</li>
            <li>Enhance Health LLC</li>
            <li>Enhance Insurance LLC</li>
            <li>EverQuote *</li>
            <li>Family Recovery Hub</li>
            <li>Festive Health</li>
            <li>Financial Relief Solutions</li>
            <li>Find Me Health Insurance</li>
            <li>First Choice Health Company</li>
            <li>First Family Insurance</li>
            <li>Gerber Life</li>
            <li>Gerber Life Insurance</li>
            <li>GET ME HEALTH CARE</li>
            <li>GetMeHealthcare</li>
            <li>Global Experts Limited LLC</li>
            <li>Go Health</li>
            <li>GoMedicare</li>
            <li>GTL</li>
            <li>Health Assistance Group</li>
            <li>Health Care Solutions Team</li>
            <li>Health Insurance Innovations</li>
            <li>Health Insurance Pros</li>
            <li>Health is Wealth Marketing LLC</li>
            <li>Healthcare.com</li>
            <li>HealthIQ</li>
            <li>Healthplan Outlook</li>
            <li>HealthPlanOne</li>
            <li>Healthspire</li>
            <li>Healthubb llc</li>
            <li>Healthy-Quotes.com</li>
            <li>Hello Medicare</li>
            <li>Hero Health, Inc</li>
            <li>Home Support Group</li>
            <li>Humana</li>
            <li>Immune Medical</li>
            <li>InboundPro</li>
            <li>Innovative Financial Group</li>
            <li>Insurance Care Direct</li>
            <li>Insurance line one</li>
            <li>Insurance Supermarket</li>
            <li>Integriant Ventures Insurance Services</li>
            <li>Jesus Network</li>
            <li>Jet Marketing</li>
            <li>Keystone interactive llc</li>
            <li>Legacy health advisors</li>
            <li>Legacy Health Insurance Advisory Agency LLC</li>
            <li>Liberty Bankers</li>
            <li>Mayberry Advisors</li>
            <li>Med Pros LLC</li>
            <li>MediaComms Networking</li>
            <li>Medicare Benefits Review Hotline</li>
            <li>Medicare FAQ</li>
            <li>Medicare10</li>
            <li>Minerva Marketing</li>
            <li>Morgan Fidelity Associates</li>
            <li>My Health Angel</li>
            <li>My legal protect</li>
            <li>M&M Platinum</li>
            <li>Naked Media</li>
            <li>National General</li>
            <li>NEW HORIZON FINANCE LLC</li>
            <li>Next LM</li>
            <li>NextLevelDirect</li>
            <li>North Star Insurance Advisors</li>
            <li>Peace Tree Insurance LLC</li>
            <li>Ping Leads</li>
            <li>Policy Scout</li>
            <li>Powderhorn Media</li>
            <li>Power Financial Relief</li>
            <li>Premier Producers Group</li>
            <li>Presidio Interactive</li>
            <li>Presidio Interactive Corporation</li>
            <li>Presidio Interactive, LLC</li>
            <li>Prime Health</li>
            <li>Prime Healthcare Benefits LLC</li>
            <li>Prosperity Life</li>
            <li>PX</li>
            <li>Quality Assurance Center</li>
            <li>Quote.com</li>
            <li>QuoteManage LLC</li>
            <li>QuoteStorm</li>
            <li>QuoteWizard</li>
            <li>Rate Quote</li>
            <li>Resource Connect</li>
            <li>Rob Graham Enterprises</li>
            <li>Rocket Health Insurance</li>
            <li>Sabal Medical Group</li>
            <li>Seek Medicare</li>
            <li>Select Quote</li>
            <li>SelectQuote</li>
            <li>SelectQuote Insurance Agency</li>
            <li>SelectQuote Insurance Services</li>
            <li>Senior Benefits</li>
            <li>senior medical group</li>
            <li>senior product advisors</li>
            <li>senior product group</li>
            <li>Senior solutions group</li>
            <li>Senior Support Pros</li>
            <li>Seniorcare USA</li>
            <li>Sharpen product advisors</li>
            <li>Sharpen Solutions Group</li>
            <li>simple life debt solutions</li>
            <li>Simple life solutions</li>
            <li>SmartCare</li>
            <li>Smart Match Insurance Agency</li>
            <li>SolidQuote LLC</li>
            <li>Support First</li>
            <li>Synergy</li>
            <li>Synergy Insurance</li>
            <li>Synergy Insurance Marketing</li>
            <li>Synergy Marketing Associates inc</li>
            <li>Tapert Insurance Agency, Inc</li>
            <li>Taylored Legacy</li>
            <li>The Medicare Basics</li>
            <li>TheFHTA</li>
            <li>The Wisdom Companies LLC </li>
            <li>TOBIAS AND ASSOCIATES INC</li>
            <li>TogetherHealth</li>
            <li>Total Insurance Brokers</li>
            <li>Tranzact</li>
            <li>True Coverage</li>
            <li>trustedconsumer.com</li>
            <li>TZ Insurance Solutions</li>
            <li>United Healthcare</li>
            <li>United Medicare Advisors</li>
            <li>UnitedHealth</li>
            <li>US Health Advisors</li>
            <li>VantageCare Insurance</li>
            <li>WCMD Services Inc.</li>
            <li>Wcmdservices Inc</li>
            <li>WORK</li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Tcpa;
